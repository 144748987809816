import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/simple-layout.js';
import BlogPostAuthor from '../components/blog-post-author';
import { useSiteImage } from '../hooks/use-site-image';
import { useSiteMetadata } from '../hooks/use-site-metadata';

const renderExcerpt = (post) => {
  return post.frontmatter.description || post.excerpt;
};

export const pageQuery = graphql`
  query CategoryQuery($title: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { title: { eq: $title } } } }
    ) {
      totalCount
      nodes {
        id
        excerpt(pruneLength: 160)
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          lastUpdated
          formattedDate: date(formatString: "MMMM DD, YYYY")
          formattedLastUpdated: lastUpdated(
            formatString: "MMMM DD, YYYY"
          )
          category {
            title
            href
          }
          tags
          featuredImage {
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 730)
              }
            }
            imageAlt
            imageTitleHtml
          }
        }
        timeToRead
      }
    }
  }
`;

export default function Page({ data, location, pageContext }) {
  const {
    title,
    // href,
    description,
    pageHeading,
    pageSubtitle,
    author,
  } = pageContext;
  const posts = data.allMarkdownRemark.nodes;
  const { siteImage } = useSiteImage();
  const { siteMetadata } = useSiteMetadata();

  return (
    <Layout
      title={title}
      description={description}
      image={siteImage}
      canonicalURL={siteMetadata.siteUrl + location.pathname}
    >
      <div className="relative px-4 pt-16 pb-20 mt-12 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {pageHeading}
            </h2>
            <p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 sm:mt-4">
              {pageSubtitle}
            </p>
          </div>
          <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
            {posts.map((post) => (
              <div
                key={post.frontmatter.title}
                className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              >
                <div className="flex-shrink-0">
                  <Link
                    to={post.fields.slug}
                    title={post.frontmatter.title}
                  >
                    <GatsbyImage
                      className="object-cover w-full h-48"
                      width={150}
                      height={200}
                      image={
                        post.frontmatter.featuredImage.image
                          .childImageSharp.gatsbyImageData
                      }
                      alt={post.frontmatter.featuredImage.imageAlt}
                    />
                  </Link>
                </div>
                <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-indigo-600">
                      {post.frontmatter.category && (
                        <Link
                          to={post.frontmatter.category.href}
                          title={
                            'Category: ' +
                            post.frontmatter.category.title
                          }
                          className="hover:underline"
                        >
                          {post.frontmatter.category.title}
                        </Link>
                      )}
                    </p>
                    <Link
                      to={post.fields.slug}
                      title={post.frontmatter.title}
                      className="block mt-2"
                    >
                      <p className="text-xl font-semibold text-gray-900">
                        {post.frontmatter.title}
                      </p>
                      <p
                        className="mt-3 text-base text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: renderExcerpt(post),
                        }}
                        itemProp="description"
                      />
                    </Link>
                  </div>
                  <BlogPostAuthor
                    author={author.name}
                    href={author.href}
                    date={post.frontmatter.date}
                    formattedDate={post.frontmatter.formattedDate}
                    timeToRead={post.timeToRead}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
